import { FolderResponse, Folder } from "~/types/FolderStructure";

export const useFolderStructure = () => {
  const getMappedFolders = (folders: Array<FolderResponse>): Array<Folder> => {
    const mappedFolders = folders.map((folder) => {
      return {
        id: folder.id,
        uuid: folder.uuid,
        title: folder.title ?? "Untitled",
        folderSortOrder: folder.folderSortOrder,
        createdAt: folder.createdAt,
        creator: folder.creator,
        clientId: folder.clientId,
        isOpen: folder.isOpen,
      };
    });

    mappedFolders.sort((a, b) => a.id - b.id);

    return mappedFolders;
  };

  return {
    getMappedFolders,
  };
};
